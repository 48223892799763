import { SignIn } from '@clerk/nextjs';

//Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const SignInPage = () => {
    return (
        <Container maxWidth="xl">
            <Grid container justifyContent="center" alignItems="center">
                <Grid item sx={{ mt: 8 }}>
                    <SignIn path="/sign-in" routing="path" signUpUrl="/sign-in" />
                </Grid>
            </Grid>
        </Container>
    );
};

export default SignInPage;
